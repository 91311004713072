<template>
    <validation-observer ref="validationObserver">
        <v-form v-model="valid" ref="form" lazy-validation @submit="submit($event)">
            <v-card>
                <v-toolbar color="primary" dark height="48"> Confirm </v-toolbar>
                <v-card-text>
                    <v-row v-if="canSearchVehicleProp">
                        <v-col>
                            <v-tabs v-model="currentTab" class="text-left">
                                <v-tab>Search</v-tab>
                                <v-tab>Drop-down Selection</v-tab>
                                <v-tab>Manual Capture</v-tab>

                                <v-tab-item>
                                    <v-sheet class="pt-3">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="Vehicle"
                                            rules="required|vehicle_required"
                                        >
                                            <v-combobox
                                                id="searchVehicle-search"
                                                class="vehicle-search-textfield"
                                                item-text="vehicleSummary"
                                                item-value="uniqueId"
                                                v-model="selectedVehicle"
                                                @input="vehicleSelected = true"
                                                @change="vehicleSelected = true"
                                                append-icon=""
                                                ref="searchVehicleSearch"
                                                :items="vehicles"
                                                :search-input.sync="searchTerm"
                                                :menu-props="{
                                                    allowOverflow: true,
                                                    closeOnClick: false,
                                                    closeOnContentClick: false,
                                                    disableKeys: true,
                                                    openOnClick: false,
                                                    maxHeight: 500,
                                                }"
                                                chips
                                                return-object
                                                no-filter
                                                hide-details
                                                outlined
                                                rounded
                                                :error-messages="errors"
                                            >
                                                <template
                                                    #prepend-inner
                                                    v-if="$vuetify.breakpoint.smAndUp"
                                                >
                                                    <v-icon color="primary">mdi-magnify</v-icon>
                                                </template>

                                                <template
                                                    #append
                                                    v-if="$vuetify.breakpoint.smAndUp"
                                                >
                                                    <v-progress-circular
                                                        v-if="searchIsLoading"
                                                        :size="20"
                                                        :width="2"
                                                        color="primary"
                                                        indeterminate
                                                    />
                                                </template>

                                                <template #no-data>
                                                    <v-list-item
                                                        v-if="
                                                            _.size(vehicles) === 0
                                                                && searchIsLoading === false
                                                                && _.size(searchTerm) >= 2
                                                        "
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                Can't find your vehicle?
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>

                                                <template #item="data">
                                                    <v-list-item-avatar
                                                        size="100"
                                                        width="170"
                                                        tile
                                                        v-if="$vuetify.breakpoint.smAndUp"
                                                    >
                                                        <img
                                                            class="smc-search-image"
                                                            :src="data.item.imageUrl"
                                                        />
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle style="text-align: left">
                                                            {{ data.item.year }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-title style="text-align: left">
                                                            {{ data.item.brand }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle style="text-align: left">
                                                            {{ data.item.range }}
                                                            <br />
                                                            {{ data.item.model }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-combobox>
                                        </validation-provider>
                                    </v-sheet>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-sheet class="pt-3">
                                        <v-select
                                            v-model="selectedBrand"
                                            label="Brand"
                                            class="mb-2"
                                            hide-details
                                            rounded
                                            outlined
                                            :loading="brandsLoading"
                                            :disabled="brandList.length === 0"
                                            :items="brandList"
                                            item-text="brand"
                                            return-object
                                            :menu-props="{
                                                contentClass: 'text-left',
                                            }"
                                        />

                                        <v-select
                                            v-model="selectedYear"
                                            label="Year"
                                            class="dropdown mb-2"
                                            hide-details
                                            rounded
                                            outlined
                                            :loading="yearsLoading"
                                            :disabled="yearList.length === 0"
                                            :items="yearList"
                                            item-text="year"
                                            return-object
                                            :menu-props="{
                                                contentClass: 'text-left',
                                            }"
                                        />

                                        <v-select
                                            v-model="selectedRange"
                                            label="Range"
                                            class="mb-2"
                                            hide-details
                                            rounded
                                            outlined
                                            :loading="rangesLoading"
                                            :disabled="rangeList.length === 0"
                                            :items="rangeList"
                                            item-text="range"
                                            return-object
                                            :menu-props="{
                                                contentClass: 'text-left',
                                            }"
                                        />

                                        <v-select
                                            v-model="selectedVehicle"
                                            @input="vehicleSelected = true"
                                            label="Model"
                                            class="mb-2"
                                            hide-details
                                            rounded
                                            outlined
                                            :loading="modelsLoading"
                                            :disabled="modelList.length === 0"
                                            :items="modelList"
                                            item-text="model"
                                            return-object
                                            :menu-props="{
                                                contentClass: 'text-left',
                                            }"
                                        >
                                            <template #item="data">
                                                <v-list-item-avatar
                                                    size="100"
                                                    width="170"
                                                    tile
                                                    v-if="$vuetify.breakpoint.smAndUp"
                                                >
                                                    <img
                                                        class="smc-search-image"
                                                        :src="data.item.imageUrl"
                                                    />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle style="text-align: left">
                                                        {{ data.item.year }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title style="text-align: left">
                                                        {{ data.item.brand }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle style="text-align: left">
                                                        {{ data.item.range }}
                                                        <br />
                                                        {{ data.item.model }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-sheet>
                                </v-tab-item>

                                <v-tab-item>
                                    <validation-observer
                                        ref="manualVehicleFormValidationObserver"
                                    >
                                        <v-form
                                            v-model="manualVehicleValid"
                                            lazy-validation
                                            ref="manualVehicleForm"
                                        >
                                            <div class="pt-1 mt-1"></div>
                                            <v-container class="pa-4">
                                                <v-row>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Vehicle manufacturer"
                                                            rules="required"
                                                        >
                                                            <v-text-field
                                                                id="manualVehicle-brand"
                                                                v-model="selectedVehicle.brand"
                                                                @input="sanitizeInput('brand')"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                prepend-inner-icon="mdi-car"
                                                                label="Vehicle manufacturer"
                                                                hint="e.g.: Audi"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Engine capacity"
                                                            rules="required"
                                                        >
                                                            <v-text-field
                                                                id="manualVehicle-capacity"
                                                                v-model="selectedVehicle.capacity"
                                                                @input="sanitizeInput('capacity')"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                prepend-inner-icon="mdi-car"
                                                                label="Engine capacity"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Vehicle range"
                                                            rules="required"
                                                        >
                                                            <v-text-field
                                                                id="manualVehicle-range"
                                                                v-model="selectedVehicle.range"
                                                                @input="sanitizeInput('range')"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                prepend-inner-icon="mdi-car-back"
                                                                label="Vehicle range"
                                                                hint="e.g.: A4"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Vehicle transmission type"
                                                            rules="required"
                                                        >
                                                            <v-select
                                                                id="manualVehicle-transmissionType"
                                                                v-model="selectedVehicle.transmissionType"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                :items="['Manual', 'Automatic']"
                                                                prepend-inner-icon="mdi-car-shift-pattern"
                                                                label="Vehicle transmission type"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Vehicle model"
                                                            rules="required"
                                                        >
                                                            <v-text-field
                                                                id="manualVehicle-model"
                                                                v-model="selectedVehicle.model"
                                                                @input="sanitizeInput('model')"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                prepend-inner-icon="mdi-car-back"
                                                                label="Vehicle model"
                                                                hint="e.g.: 2.0 TFSI"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Vehicle fuel type"
                                                            rules="required"
                                                        >
                                                            <v-select
                                                                id="manualVehicle-fuelType"
                                                                v-model="selectedVehicle.fuelType"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                :items="[
                                                                    'Petrol',
                                                                    'Diesel',
                                                                    'Hybrid',
                                                                    'Electric',
                                                                ]"
                                                                prepend-inner-icon="mdi-gas-station"
                                                                label="Vehicle fuel type"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Vehicle year"
                                                            :rules="`required|numeric|min_value:1960|max_value:${currentYear}`"
                                                        >
                                                            <v-combobox
                                                                id="manualVehicle-year"
                                                                v-model="selectedVehicle.year"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                :items="manualVehicleYearList"
                                                                prepend-inner-icon="mdi-car-2-plus"
                                                                label="Vehicle year"
                                                                hint="e.g.: 2015"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" md="6" class="py-1">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Vehicle drive type"
                                                            rules="required"
                                                        >
                                                            <v-select
                                                                id="manualVehicle-driveType"
                                                                v-model="selectedVehicle.driveType"
                                                                :disabled="vehicleSelected"
                                                                :error-messages="errors"
                                                                :items="[
                                                                    'Front wheel drive',
                                                                    'Rear wheel drive',
                                                                    'All wheel drive',
                                                                    `I'm not sure`,
                                                                ]"
                                                                prepend-inner-icon="mdi-car-side"
                                                                label="Vehicle drive type"
                                                                outlined
                                                                rounded
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col class="text-right">
                                                        <v-btn
                                                            v-if="vehicleSelected"
                                                            @click="editManualCapture"
                                                            color="primary"
                                                            small
                                                            outlined
                                                            rounded
                                                        >
                                                            Edit
                                                        </v-btn>
                                                        <v-btn
                                                            v-if="!vehicleSelected"
                                                            @click="saveManualCapture"
                                                            color="primary"
                                                            small
                                                            outlined
                                                            rounded
                                                        >
                                                            Save
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </validation-observer>
                                </v-tab-item>
                            </v-tabs>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row
                            v-if="!vehicleSelected && !valid && currentTab !== 2"
                            class="mt-0"
                        >
                            <v-col>
                                <v-alert
                                    border="right"
                                    type="error"
                                    class="mb-0"
                                    dense
                                    rounded
                                    outlined
                                >
                                    No vehicle selected
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-row v-if="vehicleSelected">
                            <v-col>
                                <v-avatar class="ma-3" size="125" width="90%" tile>
                                    <img
                                        v-if="selectedVehicle.imageUrl"
                                        :src="selectedVehicle.imageUrl"
                                        class="smc-search-result-image"
                                    />
                                    <img
                                        v-else
                                        :src="
                                            require('@/assets/images/no-entry-for-motor-vehicle.png')
                                        "
                                        class="smc-search-result-image"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col class="text-left" v-if="$vuetify.breakpoint.mdAndUp">
                                <div class="ma-3">
                                    {{ selectedVehicle.year }}
                                    <br />
                                    {{ selectedVehicle.brand }}
                                    <br />
                                    {{ selectedVehicle.range }}
                                    <br />
                                    {{ selectedVehicle.model }}
                                    <br />
                                    {{ selectedVehicle.fuelType }}
                                    <br />
                                    {{ selectedVehicle.transmissionType }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-row class="mb-3">
                        <v-divider class="mx-n3" />
                    </v-row>
                    <v-row>
                        <v-col class="form-label-col shrink" cols="12" sm="2">
                            <label class="v-label profile-label font-weight-bold">
                                Name
                            </label>
                        </v-col>
                        <v-col class="pb-0">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Name"
                                :rules="isOrganization ? `required` : ''"
                            >
                                <v-text-field
                                    :prefix="isOrganization ? organizationVehiclePrepend : ''"
                                    dense
                                    outlined
                                    rounded
                                    hint="Give the vehicle a nickname"
                                    v-model="vehicleMapping.name"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="form-label-col shrink" cols="12" sm="2">
                            <label class="v-label profile-label font-weight-bold">
                                Color
                            </label>
                        </v-col>
                        <v-col class="pb-0">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Color"
                                :rules="isOrganization ? `required` : ''"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    rounded
                                    hint="What color is the vehicle"
                                    v-model="vehicleMapping.vehicleColour"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="form-label-col shrink" cols="12" sm="2">
                            <label class="v-label profile-label font-weight-bold">
                                VIN number
                            </label>
                        </v-col>
                        <v-col class="pb-0">
                            <validation-provider
                                v-slot="{ errors }"
                                name="VIN number"
                                ref="VehicleVinNumberRef"
                                :rules="`${
                                    isOrganization ? 'required|' : ''
                                }min:17|max:${vinLength}`"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    rounded
                                    hint="Capture vehicle VIN number"
                                    v-model="vehicleMapping.vehicleVinNumber"
                                    :error-messages="getErrors('VehicleVinNumber', errors)"
                                    :readonly="!!vehicleMappingProp.innovationPolicy"
                                    :disabled="!!vehicleMappingProp.innovationPolicy"
                                    :counter="vinLength"
                                    @keyup="vinKeyUp"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="
                            vehicleMapping.vehicleVinNumber
                                && vehicleMapping.vehicleVinNumber.length === vinLength
                                && !validateVin(vehicleMapping.vehicleVinNumber)
                        "
                    >
                        <v-col>
                            <v-alert class="text-left" type="info" outlined border="left">
                                It looks like your VIN number might not be correct.
                                <br />
                                Please note that the following letters never appear in VIN
                                numbers: I, O, Q
                                <br />
                                If you are sure that the VIN number is correct, you can proceed
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="form-label-col shrink" cols="12" sm="2">
                            <label class="v-label profile-label font-weight-bold">
                                Engine number
                            </label>
                        </v-col>
                        <v-col class="pb-0">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Engine number"
                                ref="VehicleEngineNumberRef"
                                :rules="isOrganization ? `required` : ''"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    rounded
                                    hint="Capture vehicle engine number"
                                    v-model="vehicleMapping.vehicleEngineNumber"
                                    :error-messages="getErrors('VehicleEngineNumber', errors)"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="form-label-col shrink" cols="12" sm="2">
                            <label class="v-label profile-label font-weight-bold">
                                License plate
                            </label>
                        </v-col>
                        <v-col class="pb-0">
                            <validation-provider
                                v-slot="{ errors }"
                                name="License plate"
                                ref="VehicleLicensePlateRef"
                                :rules="isOrganization ? `required` : ''"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    rounded
                                    hint="Capture license plate"
                                    v-model="vehicleMapping.vehicleLicensePlate"
                                    :error-messages="getErrors('VehicleLicensePlate', errors)"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="form-label-col shrink" cols="12" sm="2">
                            <label class="v-label profile-label font-weight-bold">
                                Mileage
                            </label>
                        </v-col>
                        <v-col class="pb-0">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Mileage"
                                :rules="`${
                                    isOrganization ? 'required|' : ''
                                }numeric|min_value:${currentMileage}|max_value:1000000`"
                                ref="VehicleMileageRef"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    rounded
                                    hint="Capture vehicle mileage"
                                    v-model="vehicleMapping.vehicleMileage"
                                    :error-messages="getErrors('VehicleMileage', errors)"
                                    maxLength="6"
                                    type="number"
                                    @keyup.enter="$event.target.blur()"
                                    suffix="KM"
                                    :autofocus="editVehicleCaptureMileageProp"
                                />
                            </validation-provider>
                            <v-dialog
                                v-model="mileageHistoryShow"
                                width="800"
                                v-if="
                                    vehicleMapping.customerVehicleMappingMileageHistories
                                        && vehicleMapping.customerVehicleMappingMileageHistories.length
                                            > 0
                                "
                            >
                                <v-toolbar color="primary" dark height="48">
                                    Mileage History
                                </v-toolbar>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        secondary
                                        rounded
                                        outlined
                                        class="float-right mb-3"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        Mileage History
                                    </v-btn>
                                </template>
                                <v-card>
                                    <customer-vehicle-mileage-history
                                        ref="customerVehicleMileageHistory"
                                        :mileageHistoryDataProp="
                                            vehicleMapping.customerVehicleMappingMileageHistories
                                        "
                                        :vehicleProp="vehicleMappingProp.vehicle"
                                        :vehicleMappingProp="vehicleMappingProp"
                                        @vehicleMappingUpdated="vehicleMappingUpdated"
                                    />
                                    <v-divider />
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                            color="primary"
                                            rounded
                                            class="px-4"
                                            @click="mileageHistoryShow = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0" v-if="isOrganization">
                        <v-col class="form-label-col shrink" cols="12" sm="2">
                            <label class="v-label profile-label font-weight-bold">
                                Dekra report
                            </label>
                        </v-col>
                        <v-col class="pb-0">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Dekra report"
                                :rules="`ext:pdf|size:5000`"
                            >
                                <v-file-input
                                    v-model="vehicleReportAttachment"
                                    :error-messages="errors"
                                    append-icon="mdi-paperclip"
                                    dense
                                    prepend-icon
                                    outlined
                                    rounded
                                    counter
                                    show-size
                                    accept="application/pdf"
                                >
                                    <template #selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="primary accent-4"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>
                                        <span
                                            v-else-if="index === 2"
                                            class="overline grey--text text--darken-3 mx-2"
                                        >
                                            +{{ files.length - 2 }} File(s)
                                        </span>
                                    </template>
                                </v-file-input>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        rounded
                        class="smc-text-button smc-small-button"
                        :class="this.$vuetify.breakpoint.smAndDown ? 'mb-10' : ''"
                        color="secondary"
                        @click="cancelEditVehicle"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        rounded
                        class="smc-text-button smc-small-button"
                        :class="this.$vuetify.breakpoint.smAndDown ? 'mb-10' : ''"
                        color="primary"
                        type="submit"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Typed from 'typed.js';

import validate from '@/mixins/validator';
import { userService, vehicleService } from '@/services';
import customerVehicleMileageHistory from './customer-vehicle-mileage-history';

export default {
    name: 'customer-vehicle-edit',
    components: {
        customerVehicleMileageHistory,
    },
    mixins: [validate],
    props: {
        canSearchVehicleProp: Boolean,
        vehicleMappingProp: Object,
        editVehicleCaptureMileageProp: Boolean,
    },
    data: () => ({
        loading: false,
        valid: true,
        vehicleMapping: {
            vehicle: undefined,
            customerVehicleMappingMileageHistories: [],
        },
        vehicleReportAttachment: undefined,
        mileageHistoryShow: false,
        currentMileage: 0,

        currentTab: undefined,
        vehicleSelected: false,

        searchTerm: null,
        searchIsLoading: false,
        vehicles: [],
        selectedVehicle: undefined,

        vehicle: undefined,
        errorMessages: {},
        organizationVehiclePrepend: 'STOCK #',

        brandsLoading: false,
        brandQuery: '',
        brandList: [],
        selectedBrand: undefined,

        yearsLoading: false,
        yearsQuery: '',
        yearList: [],
        selectedYear: undefined,

        rangesLoading: false,
        rangeQuery: '',
        rangeList: [],
        selectedRange: undefined,

        modelsLoading: false,
        modelQuery: '',
        modelList: [],

        manualVehicleValid: true,
    }),
    async created() {
        this.setVehicle(this.vehicleMappingProp);

        if (this.canSearchVehicleProp) {
            setTimeout(() => {
                new Typed('#searchVehicle-search', {
                    strings: process.env.VUE_APP_SEARCH_PLACEHOLDER.split(';'),
                    attr: 'placeholder',
                    typeSpeed: 40,
                    backSpeed: 10,
                    backDelay: 3500,
                    loop: true,
                });
            }, 500);
        }
    },
    watch: {
        async currentTab(val) {
            if (val === 1 && this.brandList.length === 0) {
                await this.loadBrands();
            }
            else if (val === 2 && this.selectedVehicle === undefined) {
                this.selectedVehicle = {};
            }
        },
        selectedVehicle(val) {
            if (val) {
                this.valid = true;
            }
        },
        'searchTerm': async function (val) {
            if (val) {
                if (val.length >= 2) {
                    this.searchIsLoading = true;
                    try {
                        this.vehicles = await vehicleService.searchVehicles(val);
                        this.searchIsLoading = false;
                    }
                    catch (error) {
                        if (error.__CANCEL__) {
                            // do essentially nothing
                        }
                    }
                }
                else {
                    this.vehicles = undefined;
                }
            }
        },
        'selectedBrand': async function () {
            this.selectedYear = undefined;
            this.yearList = [];
            this.selectedRange = undefined;
            this.rangeList = [];
            this.modelList = [];

            await this.loadYears();
        },
        'selectedYear': async function () {
            this.selectedRange = undefined;
            this.rangeList = [];
            this.modelList = [];

            await this.loadRanges();
        },
        'selectedRange': async function (val) {
            if (val !== undefined) {
                this.modelList = [];

                await this.loadModels();
            }
        },
        'vehicleMappingProp': function (val) {
            this.setVehicle(val);
        },
        'vehicleMapping.vehicleVinNumber': function () {
            delete this.errorMessages['VehicleVinNumber'];
        },
        'vehicleMapping.vehicleEngineNumber': function () {
            delete this.errorMessages['VehicleEngineNumber'];
        },
        'vehicleMapping.vehicleLicensePlate': function () {
            delete this.errorMessages['VehicleLicensePlate'];
        },
        'vehicleMapping.vehicleMileage': function () {
            delete this.errorMessages['VehicleMileage'];
        },
    },
    computed: {
        isOrganization() {
            return this.$store.getters['user/isOrganization'];
        },
        currentYear() {
            return moment().year();
        },
        manualVehicleYearList() {
            let beginningYear = 1960;
            let yearsList = [];
            for (let i = this.currentYear; i >= beginningYear; i--) {
                yearsList.push(i);
            }
            return yearsList;
        },
        vinLength() {
            if (this.selectedVehicle) {
                let overrides = process.env.VUE_APP_VIN_LENGTH_OVERRIDES;
                if (overrides) {
                    let vm = this;
                    let overrideList = JSON.parse(overrides);
                    let brandOverride = _.find(overrideList, function (item) {
                        return _.toUpper(vm.selectedVehicle.brand).includes(
                            _.toUpper(item.brand),
                        );
                    });
                    if (brandOverride) {
                        return brandOverride.vin_length;
                    }
                }
            }

            return 17;
        },
    },
    methods: {
    //#region load-methods
        async loadBrands() {
            this.brandsLoading = true;
            this.brandList = await vehicleService.getBrands();
            this.brandsLoading = false;
        },
        async loadYears() {
            this.yearsLoading = true;
            this.yearList = await vehicleService.getYears(this.selectedBrand.brand);
            this.yearsLoading = false;
        },
        async loadRanges() {
            this.rangesLoading = true;
            this.rangeList = await vehicleService.getRanges(
                this.selectedBrand.brand,
                this.selectedYear.year,
            );
            this.rangesLoading = false;
        },
        async loadModels() {
            this.modelsLoading = true;

            this.modelList = await vehicleService.getModels(
                this.selectedBrand.brand,
                this.selectedYear.year,
                this.selectedRange.range,
            );

            this.modelsLoading = false;
        },
        //#endregion

        //#region manual-capture-methods
        editManualCapture() {
            if (this.selectedVehicle.diskDriveReference) {
                this.selectedVehicle = {
                    brand: this.selectedVehicle.brand,
                    range: this.selectedVehicle.range,
                    model: this.selectedVehicle.model,
                    year: this.selectedVehicle.year,
                    capacity: this.selectedVehicle.capacity,
                    transmissionType: this.selectedVehicle.transmissionType,
                    fuelType: this.selectedVehicle.fuelType,
                    driveType: this.selectedVehicle.driveType,
                };
            }
            this.vehicleSelected = false;
        },
        sanitizeInput(fieldName) {
            // Remove single quote (') character from the input value
            const sanitizedValue = this.selectedVehicle[fieldName].replace(/'/g, '');

            // Assign the sanitized value back to the corresponding field in selectedVehicle
            this.selectedVehicle[fieldName] = sanitizedValue;
        },
        async saveManualCapture() {
            if (await this.$refs.manualVehicleFormValidationObserver.validate()) {
                this.vehicleSelected = true;
                this.valid = true;
            }
        },
        //#endregion

        cancelEditVehicle() {
            this.$emit('cancelVehicleMappingEdit');
        },
        getErrors(field, errors) {
            let errMessages = this.errorMessages[field];
            if (!errMessages) {
                return errors;
            }
            return _.concat(errors, errMessages);
        },
        setVehicle(vehicleMapping) {
            this.vehicleMapping = _.cloneDeep(vehicleMapping);
            if (this.isOrganization) {
                if (
                    _.startsWith(
                        this.vehicleMapping.name,
                        this.organizationVehiclePrepend,
                    )
                ) {
                    this.vehicleMapping.name = _.trimStart(
                        this.vehicleMapping.name,
                        this.organizationVehiclePrepend,
                    );
                }
            }
            if (this.vehicleMapping.vehicle) {
                this.selectedVehicle = _.cloneDeep(this.vehicleMapping.vehicle);
                this.vehicleSelected = true;
            }
            this.currentMileage = this.vehicleMapping.vehicleMileage;
            if (!this.currentMileage) {
                this.currentMileage = 0;
            }
        },
        vinKeyUp() {
            if (
                this.vehicleMapping.vehicleVinNumber
                && this.vehicleMapping.vehicleVinNumber.length === this.vinLength
            ) {
                this.valid = true;
            }
        },
        vehicleMappingUpdated(vehicleMapping) {
            this.vehicleMapping = vehicleMapping;
            this.$emit('updateVehicleMapping', vehicleMapping);
        },
        async submit($event) {
            $event.preventDefault();

            if (
                this.vehicleSelected
                && (await this.$refs.validationObserver.validate())
            ) {
                this.loading = true;
                this.editVehicleShow = false;
                this.vehicleMapping.vehicle = this.selectedVehicle;

                if (this.isOrganization) {
                    if (
                        !_.startsWith(
                            this.vehicleMapping.name,
                            this.organizationVehiclePrepend,
                        )
                    ) {
                        this.vehicleMapping.name = `${this.organizationVehiclePrepend}${this.vehicleMapping.name}`;
                    }
                }

                let result = await userService.upsertVehicle(
                    this.vehicleMapping,
                    this.vehicleReportAttachment,
                );
                if (result.status === 200) {
                    let showMileage = result.data.vehicleMileage
                        !== this.vehicleMappingProp.vehicleMileage;
                    this.clearAll();
                    this.$emit('updateVehicleMapping', result.data);
                    if (showMileage) {
                        this.$emit('showVehicleMileageHistory');
                    }
                    else {
                        this.$emit('closeCustomerVehicleEdit');
                    }
                }
                else {
                    let vm = this;
                    let validationKeys = [];
                    _.mapKeys(result.data, function (value, key) {
                        vm.errorMessages[key] = value;
                        validationKeys.push(key);
                    });
                    validationKeys.forEach(key => {
                        this.$refs[`${key}Ref`].validate();
                    });
                }
                this.loading = false;
            }
            else {
                this.valid = false;
            }
        },
        clearAll() {
            this.modelList = [];
            this.rangeList = [];
            this.yearList = [];
            this.selectedVehicle = undefined;
            this.selectedRange = undefined;
            this.selectedYear = undefined;
            this.selectedBrand = undefined;

            this.vehicles = [];

            this.vehicleSelected = false;
            this.valid = true;

            this.vehicleMapping = {
                vehicle: undefined,
                customerVehicleMappingMileageHistories: [],
            };
        },
    },
};
</script>

<style lang="scss">
.form-label-col {
  @media screen and (max-width: 420px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.profile-label {
  line-height: 40px;
  white-space: nowrap;

  @media screen and (max-width: 420px) {
    line-height: initial;
  }
}

.form-label-col {
  min-width: 130px;
  text-align: left;
}

.vehicle-search-textfield {
  max-height: 56px;
}

.vehicle-search-textfield .v-input__control {
  max-height: 56px;
}

.vehicle-search-textfield .v-select__selections {
  max-height: 56px;
}

.vehicle-search-textfield .v-input__slot {
  max-height: 56px;
}

.vehicle-dropdown .v-list-item .v-list-item__title {
  text-align: left;
}
</style>
